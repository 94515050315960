<template>
    <base-expansion-panel :selected="selected" class="payment-flow-method-step-new-method-card vuetify">
        <template #title>
            <div class="horizontal-fields">
                <div><h1 class="saved-title">{{ $translate('paymentFlow.method.newCard') }}</h1></div>
                <base-spacer></base-spacer>
                <div class="cc-icons">
                    <base-icon icon="custom:AmexSVG"></base-icon>
                    <base-icon icon="custom:MastercardSVG"></base-icon>
                    <base-icon icon="custom:DiscoverSVG"></base-icon>
                    <base-icon icon="custom:VisaSVG"></base-icon> 
                </div>
            </div>
        </template>
        <template v-slot:expanded>
            <div class="card-content">
                <div class="horizontal-fields">
                    <base-text-field
                        v-model="card.firstName"
                        :label="$translate('labels.firstName')"
                        :error-messages="v$.card.firstName.$errors.map(e => e.$message)"
                        class="fs-block first-of-type first-name x2"
                        name="firstName"
                        required
                        @blur="v$.card.firstName.$touch"
                    ></base-text-field>
                    <base-text-field
                        v-model="card.lastName"
                        :label="$translate('labels.lastName')"
                        :error-messages="v$.card.lastName.$errors.map(e => e.$message)"
                        class="fs-block last-name x2"
                        name="lastName"
                        required
                        @blur="v$.card.lastName.$touch"
                    ></base-text-field>
                </div>
                <base-text-field
                    v-model="card.number"
                    :label="$translate('labels.cardNumber')"
                    :error-messages="v$.card.number.$errors.map(e => e.$message)"
                    class="fs-block credit-card-number"
                    name="cardNumber"
                    ref="cardNumber"
                    @keypress="onlyNumbers($event)"
                    :maxlength="issuerCardLength"
                    required
                    @blur="v$.card.number.$touch"
                    inputmode="decimal"
                    :append-inner-icon="cardLogo"
                >
                </base-text-field>
                <div class="exp-cvv-fields vuetify">
                    <base-text-field
                        v-model="card.expiration"
                        :label="$translate('labels.cardExpirationDate')"
                        :error-messages="v$.card.expiration.$errors.map(e => e.$message)"
                        class="fs-block credit-card-expiration"
                        name="cardExpirationDate"
                        @keypress="onlyNumbers($event)"
                        maxlength="5"
                        required
                        @blur="v$.card.expiration.$touch"
                        inputmode="decimal"
                    ></base-text-field>
                    <base-text-field
                        v-model="card.cvv"
                        :label="$translate('labels.cvvFormat')"
                        :error-messages="v$.card.cvv.$errors.map(e => e.$message)"
                        class="fs-block credit-card-cvv"
                        name="cardCvv"
                        @keypress="onlyNumbers($event)"
                        :maxlength="issuerCvvLength"
                        required
                        @blur="v$.card.cvv.$touch"
                        inputmode="decimal"
                    ></base-text-field>
                </div>
            </div>
            <payment-flow-billing-address v-model="billing" type="card" ref="paymentFlowBillingAddress"></payment-flow-billing-address>
            <div class="horizontal-fields save-card vuetify">
                <base-checkbox
                    v-if="!hideSaveOption"
                    v-model="card.saveCard"
                    :label="$translate('labels.saveCard')"
                    class="vuetify save-card-checkbox"
                    name="saveCard"
                    ref="saveCard"
                ></base-checkbox>
            </div>
        </template>
    </base-expansion-panel>
</template>

<script>
import BaseSelect from './BaseSelect.vue';
import _ from 'lodash';
import { onlyNumbers, states, CardUtils } from './../utils/utils.js';
import BaseExpansionPanel from './BaseExpansionPanel.vue';
import BaseTextField from './BaseTextField.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseCheckbox from './BaseCheckbox.vue';
import BaseIcon from './BaseIcon.vue';
import PaymentFlowBillingAddress from './PaymentFlowBillingAddress.vue';

import useValidate from '@vuelidate/core'
import { required, maxLength, minLength, helpers } from '@vuelidate/validators'

export default {
    name: 'PaymentFlowMethodStepNewMethodCard',
    props: {
        hideSaveOption: {
            type: Boolean,
            default: false,
        },
        isPaymentPlan: Boolean,
        selected: Boolean,
        modelValue: Object
    },
    components: {
        BaseSelect,
        BaseTextField,
        BaseExpansionPanel,
        BaseSpacer,
        BaseCheckbox,
        BaseIcon,
        PaymentFlowBillingAddress
    },
    data: () => ({
        v$: useValidate(),
        card: {
            firstName: '',
            lastName: '',
            number: '',
            expiration: '',
            cvv: '',
            saveCard: true,
        },
        billing: {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        },
        states: states,
        issuer: null,
        issuerCardLength: 19, //16 digits and 3 spaces
        issuerCvvLength: 3,
    }),
    validations() {
        return {
            card: {
                firstName: { required: this.requiredMsg },
                lastName: { required: this.requiredMsg },
                number: {
                    required: this.requiredMsg,
                    maxLength: helpers.withMessage(
                        this.$translate('errors.ccInvalidLength', { validLength: CardUtils.getValidLengthFromIssuer(this.issuer)}),
                        maxLength(this.issuerCardLength)
                    ),
                    minLength: helpers.withMessage(
                        this.$translate('errors.ccShortInvalid'),
                        minLength(this.issuerCardLength)
                    )
                },
                expiration: { 
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.ccExpirationInvalid'),
                        minLength(5)
                    ),
                    expiredDate: {
                        $validator: this.isExpired,
                        $message: this.$translate('errors.ccIsExpired')
                    }
                },
                cvv: {
                    required: this.requiredMsg,
                    minLength: helpers.withMessage(
                        this.$translate('errors.invalidCVV'),
                        minLength(this.issuerCvvLength)
                    )
                }
            }
        }
    },
    computed: {
        requiredMsg() {
            return helpers.withMessage(this.$translate('errors.fieldRequired'), required);
        },
        cardLogo() {
            switch(this.issuer) {
                case 'American Express':
                    return 'custom:AmexSVG'
                case 'Mastercard':
                    return 'custom:MastercardSVG'
                case 'Discover':
                    return 'custom:DiscoverSVG'
                case 'Visa':
                    return 'custom:VisaSVG'
                default:
                    return null;
            }
        }
    },
    watch: {
        card: {
            handler() {
                this.updateModelValue();
            },
            deep: true 
        },
        billing: {
            handler() {
                this.updateModelValue();
            },
            deep: true 
        },
        "card.number": {
            handler(value) {
                this.getIssuerInfo(value);
                this.formatCardNumberInput();
            }
        },
        "card.expiration": {
            handler() {
                this.formatCardExpirationInput();
            }
        },
        "billing.state": {
            handler(value) {
                if (typeof value == 'string') {
                    let state = this.states.find(state => {
                        return state.value == value;
                    });
                    this.billing.state = state;
                }
            }
        }
    },
    methods: {
        onlyNumbers: onlyNumbers,
        updateModelValue() {
            let newBilling = _.cloneDeep(this.billing);
            newBilling.state = (this.billing && this.billing.state) ? this.billing.state.value : null;
            this.$emit('update:modelValue', {
                ...this.card,
                billing: newBilling,
                valid: (!this.v$.card.$invalid && this.billing.valid)
            });
        },
        isExpired(value) {
            return !CardUtils.isExpired(CardUtils.stripExtraCharacters(value));
        },
        validateFields() {
            this.$refs.paymentFlowBillingAddress.validateFields();
            this.v$.$validate();
        },
        getIssuerInfo(value) {
            let cleanVal = CardUtils.stripExtraCharacters(value);
            this.issuer = CardUtils.getIssuer(cleanVal);
            this.issuerCardLength = CardUtils.getValidLengthFromIssuer(this.issuer) + (this.issuer == 'American Express' ? 2 : 3); // add extra for spaces
            this.issuerCvvLength = CardUtils.cvvLengthBasedOnIssuer(this.issuer);
        },
        formatCardNumberInput() {
            if (this.card.number && this.card.number.length > 0) {
                var v = this.card.number.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
                var parts = [];
                if (this.issuer == 'American Express') {
                    parts.push(v.substring(0, 4));
                    if (v.length > 4) {
                        parts.push(v.substring(4, 10));
                    }
                    if (v.length > 10) {
                        parts.push(v.substring(10, 15));
                    }
                } else {
                    var matches = v.match(/\d{4,19}/g);
                    var match = matches && matches[0] || '';
                    var len = 0;
                    var i = 0;
                    for (i=0, len=match.length; i<len; i+=4) {
                        parts.push(match.substring(i, i+4));
                    }
                }
                if (parts.length) {
                    this.card.number = parts.join(' ');
                }
            }
        },
        formatCardExpirationInput() {
            if (this.card.expiration && this.card.expiration.length > 0) {
                var v = this.card.expiration.replace(/\/+/g, '').replace(/[^0-9]/gi, '');
                var matches = v.match(/\d{1,2}/g);
                if (matches.length) {
                    this.card.expiration = matches.join('/');
                }
            }
        }
    },
    async mounted() {
        await this.$nextTick();
        if (this.modelValue) {
            Object.keys(this.modelValue).forEach((key) => {
                if ('billing' !== key) {
                    this.card[key] = this.modelValue[key];
                }
            });
            if (this.modelValue.billing) {
                Object.keys(this.modelValue.billing).forEach((key) => {
                    this.billing[key] = this.modelValue.billing[key];
                });
            }
        }
        if (this.isPaymentPlan) {
            this.card.saveCard = true;
        }
    }
};
</script>

<style lang="scss">
@import './../styles/variables.scss';

.payment-flow-method-step-new-method-card {
    .v-expansion-panel-title {
        svg {
            font-size: 3rem;
            margin: 0.25rem;
        }

    }
    .v-expansion-panel-text {
        @include wider-than-tablet {
            margin-left: 3.5rem;
            margin-right: 3.5rem;
        }
    }

    .card-content {
        padding-bottom: 3.4rem;
        color: $global-color-font-new;
    }

    .credit-card-number {
        margin-top: 0;
        .v-field__append-inner svg {
            margin-top: -1rem;
        }
    }

    h2 {
        color: $global-color-font-new;
        font-size: 1.4rem;
        line-height: 2rem;
        padding-left: 0.4rem;
        padding-bottom: 0.4rem;
    }

    .exp-cvv-fields {
        display: flex;
        flex-flow: row wrap;
         & > .credit-card-expiration{
            margin-right: 0.5rem;
            margin-top: 0;
            width: 65%;
        }
        & > .credit-card-cvv {
            min-width: 8rem;
            margin-top: 0;
            width: 25%;
        }
    }

    .horizontal-fields {
        display: flex;
        flex-flow: row wrap;
        flex-direction: column;
        & > .v-text-field.vuetify {
            width: 50%;
        }
        .first-of-type {
            padding-right: 5px;
        }
    }
    @include wider-than-tablet {
        .horizontal-fields {
            flex-direction: row;
            align-items: center;
        }

        .v-expansion-panel-title > div{
            width: 100%;
        }
        
        .v-card-title{
            width: 100%;
        }
    }
}
</style>