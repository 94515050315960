import moment from 'moment';

import en from '../localization/en-US/en.json';
import encarc from '../localization/en-US/en-carc.json';
import es from '../localization/es/es.json';
import escarc from '../localization/es/es-carc.json';

import _ from 'lodash';

/*
 *  Takes in an optionally dollar amount formatted value and returns it in the form of a float or null.
 *  0 -> 0.0
 *  ## -> ##.0
 *  $##.# -> ##.#
 *  -$##.## -> -##.##
 *  $-##.## -> -##.##
 *  undefined -> null
 */
export function parseAmount(val) {
    if (val === null || val === undefined) {
        return null;
    }
    val = parseFloat(String(val).replace(/(\$|,)/g, ''));
    if (isNaN(Number(val))) {
        return null;
    }
    return val;
}


/*
 *  Takes in a value and returns it in the form of US dollars or null.
 *  0 -> $0.00
 *  ## -> $##.00
 *  undefined -> null
 */
export function formatCurrency(val, short) {
    val = parseAmount(val);
    if (val === null || val === undefined || isNaN(Number(val))) {
        return null;
    }
    var formatterArgs = {
        style: 'currency',
        currency: 'USD',
    };
    if(short) {
        formatterArgs.minimumFractionDigits = 0;
    }
    var formatter = new Intl.NumberFormat('en-US', formatterArgs);
    return formatter.format(val);
    // return '$0.00';
};

export let paymentStates = {
    UNCONFIRMED: 'UNCONFIRMED',
    ABANDONED: 'ABANDONED',
    CANCELLED: 'CANCELLED',
    SCHEDULED: 'SCHEDULED',
    HELD: 'HELD',
    DECLINED: 'DECLINED',
    REPROCESSED: 'REPROCESSED',
    PROCESSED: 'PROCESSED',
    VOIDED: 'VOIDED',
    UNDISBURSED: 'UNDISBURSED',
    DISBURSED: 'DISBURSED',
    UNKNOWN: 'UNKNOWN'
};

export let reversalReasons = {
    REFUND: 'REFUND',
    CHARGEBACK: 'CHARGEBACK',
    FAILED_ECHECK: 'FAILED_ECHECK',
    BOUNCED_CHECK: 'BOUNCED_CHECK'
};


/* 
 * Returns a date object in the format requested
 * https://momentjs.com/docs/#/displaying/format
 */
export function formatDate(dateStr, outFormat, inFormat='YYYY/MM/DD hh:mm:ss') {
    return moment(dateStr, inFormat).format(outFormat);
}


/*
 * Returns a string in the requested language
 * params should be passed in when the str needs additional information
 * params format: {key: value}
 * Currently supported: English, Spanish
 */
export function translate(str, params=null) {
    const lang = (localStorage.getItem('currentLanguage')) === 'es' ? {...es, ...escarc} : {...en, ...encarc};
    let base = _.get(lang, str, undefined);
    if (!base && str.match(/^carc\./)) {
        base = _.get(lang, 'carc.default', undefined);
    } 
    if (!base && localStorage.getItem('currentLanguage') !== 'en' ) {//fall back to English
        console.error('Missing translation',localStorage.getItem('currentLanguage'), str);
        base = _.get({...en, ...encarc}, str, undefined);
        if (!base && str.match(/^carc\./)) {
            base = _.get({...en, ...encarc}, 'carc.default', undefined);
        }
        if (!base) {
            console.error('Missing translation in English too', str);
        }
    }
    if (!base) {
        base = str;
    }
    if (params) {
        let str = base;
        Object.keys(params).forEach((paramName) => {
        str = str.split(`{{ ${paramName} }}`).join(params[paramName]);
        str = str.split(`{{${paramName}}}`).join(params[paramName]);
        });
        return str;
    }
    return base;
};


/*
 * Only allows numbers to be entered into a text field.
 * Can be used alongside maxlength input field where built-in
 * type="numbers" doesn't work and adds additional styling
 */
export function onlyNumbers(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
        return false;
    } else {
        return true;
    }
}


/* Returns a deferred promise as referred to in older javascript
 * as deferred = $q.defer();
 */
export function generateDeferredPromise() {
    let resolve, reject;
    const promise = new Promise((res, rej) => {
      [resolve, reject] = [res, rej];
    });
    return {promise, reject, resolve};
}

const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export function validEmail(email) {
    // check we have an real value and it can be lowercased
    if (email && email.toLowerCase) {
        return EMAIL_REGEX.test(email.toLowerCase());
    }
    return false;
}

export function getValidName(name) {
    return name.replace(/[^a-zA-Z ,.'-]/g, '');
}

/**
 * @param length
 * @returns string
 */
export function randomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

/*
 * methods for MoneyUtils
 */
var _toCents = function(val){
    return parseFloat(val) * 100;
},

_backToFloat = function(val){
    // note we do not try to handle rounding issues here
    // as anything using this to present data will be 
    // passed through a currency filter
    return val / 100;
};

export let MoneyUtils = {
    add : function(/*...*/){
        return _backToFloat(_.sum(_.map(arguments, function(num){
            return _toCents(num);
        })));
    },

    subtract: function(/*...*/){
        return _backToFloat(_.reduce(_.map(arguments, function(num){
            return _toCents(num);
        }), function(difference, cents){
            return difference - cents;
        }));
    },

    equals: function(/*...*/){
        var _args = arguments;
        return _.every(_args, function( num ){
            return _toCents(num) === _toCents(_args[0]);
        });
    },

    greaterThanOrEqual: function( a, b ){
        return _toCents(a) >= _toCents(b);
    },

    lessThanOrEqual: function( a, b ){
        return this.greaterThanOrEqual(b, a);
    },

    lessThan: function( a, b ){
        return _toCents(a) < _toCents(b);
    },

    greaterThan: function( a, b ){
        return _toCents(a) > _toCents(b);
    },

    onInput: function(value) {
        if (!value) {
            return value;
        }
        let entered = value;
        // strip out anything that's not a number or a decimal
        const cleaned = entered.replace(/[^\d\.]+/g, '');
        const sections = cleaned.split('.');
        const dollars = sections[0];
        const cents = (sections[1] || '').slice(0, 2);
        const decimalIndex = cleaned.indexOf('.');
        let formatted = dollars;
        if (decimalIndex >= 0) {
            formatted = formatted + '.' + cents;
        }
        return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    onBlur: function(value) {
        if (!value) {
            return '0.00';
        } else {
            const index = value.indexOf('.');
            if (index < 0) {
                // e.g. $420
                value += '.00';
            } else if (index === value.length - 1) {
                // e.g. $. or $420.
                value += '00';
            } else if (index === value.length - 2) {
                value += '0';
            }
            return value;
        }
    }
};


/**
 * Methods for CardUtils
 */

/**
 * Returns a string of words with the first letter
 * of each word capitalized
 */
var _capitalizeWords = function(string) {
    if (string) {
        const words = string.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(" ");
    }
    return '';
};
export let CardUtils = {
    getIssuer: function(number) {
        var issuer = null;
        /**
         * See if the first part of a card is within an issuers alloted range.
         * NOTE: the upperBound is non inclusive
         * so if your range is between 1-5 -> 5 will not be true
         * so go one digit higher than your max value
         */
        var numberInRange = function(number, lowerBound, upperBound){
            number = number.toString().slice(0, upperBound.toString().length);
            return _.inRange(_.parseInt(number), lowerBound, upperBound);
        };

        // trim out white space
        number = number ? _.isString(number) ? number.replace(/ /g,'') : number : '';

        if (/^5[1-5]/.test(number) || numberInRange(number, 222100, 272100) ) {
            issuer = 'Mastercard';
        } else if (/^4/.test(number)) {
            issuer = 'Visa';
        } else if (/^3[47]/.test(number)) {
            issuer = 'American Express';
        } else if (/^6(?:011|5[0-9]{2})/.test(number) || numberInRange(number, 644, 650) || numberInRange(number, 622126, 622926)) {
            issuer = 'Discover';
        }
        return issuer;
    },
    //translate the cartType enum values we get from our db enum.  All supported card types (except 
    //for amex) need only to be capitalized; amex needs a space.
    getIssuerFromCardType: function(cardType) {
        var issuer = null;
        switch(cardType.toLowerCase()) {
            case 'americanexpress':
                issuer = 'american express';
                break;
            default:
                issuer = cardType.toLowerCase();
                break;
        }
        return _capitalizeWords(issuer);
    },
    getIcon: function(issuer) {
        var icon = '';
        switch((issuer || '').toLowerCase()){
            case 'mastercard':
                icon = 'mastercard';
                break;
            case 'visa':
                icon = 'visa';
                break;
            case 'american express':
                icon = 'amex';
                break;
            case 'discover':
                icon = 'discover';
                break;
            default:
                icon = 'credit-card';
                break;
        }
        return icon;
    },
    isExpired: function(expDate) {
        var expired = false,
            expiry,
            today,
            year,
            month;

        expDate = (expDate || '').toString();

        if(expDate.length === 4){

            month = _.parseInt(expDate.slice(0, 2));
            year = _.parseInt(expDate.slice(-2));

            // do some fuzzy logic to pull out the
            // approximate year they user is referring to
            // not this is only used for expDateidations
            if (year < 70) {
                year = '20' + (year > 9 ? year : '0' + year);
            } else {
                year = '19' + (year > 9 ? year : '0' + year);
            }

            expiry = new Date(year, month);
            today = new Date();

            // account for zero based index
            // and roll the month up to the next month + 1 day
            // as cards expire when the month listed elapses
            expiry.setMonth(expiry.getMonth() - 1);
            expiry.setMonth(expiry.getMonth() + 1, 1);
            
            expired = expiry < today;
        }
        return expired;
    },
    expiresBeforePaymentDate(expDate, paymentDate) {
        var cardDate = moment(expDate, 'MMYY').endOf('month');
        var paymentDate = moment(paymentDate, 'YYYY/MM/DD');
        return paymentDate.isAfter(cardDate);
    },
    // luhn check sampled from https://gist.github.com/ShirtlessKirk/2134376
    luhnCheck: (function (arr) {
        return function (ccNum) {
            var len = ccNum.length,
                bit = 1,
                sum = 0,
                val;
        
            while (len) {
                val = parseInt(ccNum.charAt(--len), 10);
                sum += (bit ^= 1) ? arr[val] : val; /* jshint ignore:line */
            }
        
            return sum && sum % 10 === 0;
        };
    }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])),
    isValidLength: function (number) {
        // trim out white space
        number = number ? _.isString(number) ? number.replace(/ /g,'') : number : '';

        // Get issuer
        const issuer = this.getIssuer(number);

        // Get length
        const length = number.length;

        // the number of characters we allow
        // before we can definitively infer that
        // we have enough characters to treat it
        // as a number we can apply checks against
        const issuerMaxUnknownCharCount = 6;
        
        if (issuer === 'Visa') {
            return length >= 13 && length <= 16;
        } else if (_.includes(['Mastercard', 'Discover'], issuer)) {
            return length === 16;
        } else if (issuer === 'American Express') {
            return length === 15;
        }
        return length >= issuerMaxUnknownCharCount;
    },
    getValidLengthFromIssuer: function (issuer) {
        if (issuer === 'American Express') {
            return 15;
        }
        return 16;
    },
    stripExtraCharacters: function(number) {
        return number ? (typeof number === 'string') ? number.replace(/ /g, '').replace(/\//g,'') : '' : '';
    },
    cvvLengthBasedOnIssuer: function (issuer) {
        if (issuer && issuer == 'American Express') {
            return 4;
        }
        return 3;
    },
}

export function listCombine(wordList) {
    if (wordList.length === 0) { 
        return ''; 
    } else if (wordList.length === 1) { 
        return wordList[0]; 
    } 

    return _.initial(wordList).join(', ') + ' ' + translate('misc.conjunction') + ' ' + _.last(wordList); 
}

export function getMethodDescriptor(method, style, separator) {

    var _card = function(m, s, separator){

        var output = [];

        if (m.isApplePay) { //from _encodePaymentForm after a payment
            return translate('receipt.paidWithApplePayDesc');
        } else if (m.isGooglePay) {
            return translate('receipt.paidWithGooglePayDesc');
        }

        switch (s) {
            case 'short':
                output = [CardUtils.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                break;
            default:
                output = [CardUtils.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                break;
        }

        return output.join( separator === undefined ? ' ' : separator);
    },

    _eCheck = function(m, s, separator){

        var output = [];

        // we aren't always consistent with 
        // how we display these values, so we will check
        // for the routing if we don't see a 4 digits 
        if(!m.last4Digits && m.accountNumber){
            m.last4Digits = m.accountNumber.slice(-4);
        }

        switch (s) {
            case 'short':
                output = ['eCheck', '**' + m.last4Digits];
                break;
            default:
                output = ['eCheck', m.bankName, '**' + m.last4Digits];
                break;
        }

        return output.join( separator === undefined ? ' ' : separator);
    },

    _financing = function(m, s, separator){

        var output = [];

        switch (s) {
            case 'short':
                output = [m.vendor, m.accountNumber];
                break;
            case 'supershort':
                output = [m.vendor, 'Card'];
                break;
            default:
                output = [m.vendor, 'Card', m.accountNumber, 'for', m.provider];
                break;
        }

        return output.join( separator === undefined ? ' ' : separator);
    };

    var acceptedStyles = [ 'short', 'supershort' ],
        officialStyle;

    if(!method){
        return '';
    }

    if(style && acceptedStyles.indexOf(style) > -1){
        officialStyle = style.toLowerCase();
    }

    switch (method.formType.toLowerCase()) {
        case 'card':
            return _card(method, officialStyle, separator);
        case 'financing':
            return _financing(method, officialStyle, separator);
        default:
            return _eCheck(method, officialStyle, separator);
    }
}

export function titleCase(word) {
    return word.toLowerCase().split(' ').map(function(w) {
        return (w.charAt(0).toUpperCase() + w.slice(1));
    }).join(' ');
}

/**
 * Moving the states list for inputs here
 * because it's too long to keep in a components
 */
export let states = [
    {
        value: "AL",
        text: "Alabama"
    },
    {
        value: "AK",
        text: "Alaska"
    },
    {
        value: "AS",
        text: "American Samoa"
    },
    {
        value: "AZ",
        text: "Arizona"
    },
    {
        value: "AR",
        text: "Arkansas"
    },
    {
        value: "CA",
        text: "California"
    },
    {
        value: "CO",
        text: "Colorado"
    },
    {
        value: "CT",
        text: "Connecticut"
    },
    {
        value: "DE",
        text: "Delaware"
    },
    {
        value: "DC",
        text: "District Of Columbia"
    },
    {
        value: "FM",
        text: "Federated States Of Micronesia"
    },
    {
        value: "FL",
        text: "Florida"
    },
    {
        value: "GA",
        text: "Georgia"
    },
    {
        value: "GU",
        text: "Guam"
    },
    {
        value: "HI",
        text: "Hawaii"
    },
    {
        value: "ID",
        text: "Idaho"
    },
    {
        value: "IL",
        text: "Illinois"
    },
    {
        value: "IN",
        text: "Indiana"
    },
    {
        value: "IA",
        text: "Iowa"
    },
    {
        value: "KS",
        text: "Kansas"
    },
    {
        value: "KY",
        text: "Kentucky"
    },
    {
        value: "LA",
        text: "Louisiana"
    },
    {
        value: "ME",
        text: "Maine"
    },
    {
        value: "MH",
        text: "Marshall Islands"
    },
    {
        value: "MD",
        text: "Maryland"
    },
    {
        value: "MA",
        text: "Massachusetts"
    },
    {
        value: "MI",
        text: "Michigan"
    },
    {
        value: "MN",
        text: "Minnesota"
    },
    {
        value: "MS",
        text: "Mississippi"
    },
    {
        value: "MO",
        text: "Missouri"
    },
    {
        value: "MT",
        text: "Montana"
    },
    {
        value: "NE",
        text: "Nebraska"
    },
    {
        value: "NV",
        text: "Nevada"
    },
    {
        value: "NH",
        text: "New Hampshire"
    },
    {
        value: "NJ",
        text: "New Jersey"
    },
    {
        value: "NM",
        text: "New Mexico"
    },
    {
        value: "NY",
        text: "New York"
    },
    {
        value: "NC",
        text: "North Carolina"
    },
    {
        value: "ND",
        text: "North Dakota"
    },
    {
        value: "MP",
        text: "Northern Mariana Islands"
    },
    {
        value: "OH",
        text: "Ohio"
    },
    {
        value: "OK",
        text: "Oklahoma"
    },
    {
        value: "OR",
        text: "Oregon"
    },
    {
        value: "PW",
        text: "Palau"
    },
    {
        value: "PA",
        text: "Pennsylvania"
    },
    {
        value: "PR",
        text: "Puerto Rico"
    },
    {
        value: "RI",
        text: "Rhode Island"
    },
    {
        value: "SC",
        text: "South Carolina"
    },
    {
        value: "SD",
        text: "South Dakota"
    },
    {
        value: "TN",
        text: "Tennessee"
    },
    {
        value: "TX",
        text: "Texas"
    },
    {
        value: "UT",
        text: "Utah"
    },
    {
        value: "VT",
        text: "Vermont"
    },
    {
        value: "VI",
        text: "Virgin Islands"
    },
    {
        value: "VA",
        text: "Virginia"
    },
    {
        value: "WA",
        text: "Washington"
    },
    {
        value: "WV",
        text: "West Virginia"
    },
    {
        value: "WI",
        text: "Wisconsin"
    },
    {
        value: "WY",
        text: "Wyoming"
    },
];

/**
 * We don't want to show detail lines that don't have descriptions, so we aggregate any that don't have one.
 * If none have one, we give up.
 */
export function squashDescriptionlessDetailLines(detailLines) {
    let descriptionless = detailLines.filter(d => !d.description);
    if (descriptionless.length == detailLines.length) {
        return []; //don't show any detaillines if we don't have any descriptions
    } else if (descriptionless.length === 0) {
        return detailLines;
    }
    let descriptioned = detailLines.filter(d => !!d.description);
    let squashed = descriptionless.reduce((acc, cur) => {
        acc.insuranceAdjustment += cur.insuranceAdjustment;
        acc.insurancePaid += cur.insurancePaid;
        acc.patientPaid += cur.patientPaid;
        acc.patientResponsibility += cur.patientResponsibility;
        acc.providerAdjustment += cur.providerAdjustment;
        acc.totalCharge += cur.totalCharge;
        cur.carcCodes.forEach(cc => {
            let i = acc.carcCodes.findIndex(c => c.carcCode == cc.carcCode);
            if (i < 0) {
                acc.carcCodes.push(cc);
            } else {
                acc.carcCodes[i].amount += cc.amount;
            }
        });
        return acc;
    }, {
        cptCode: "",
        description: translate("labels.squashedDescriptionless", {count: descriptionless.length, plural: descriptionless.length === 1?'':'s'}),
        insuranceAdjustment: 0,
        insurancePaid: 0,
        patientPaid: 0,
        patientResponsibility: 0,
        providerAdjustment: 0,
        totalCharge: 0,
        carcCodes: [
            {
                carcCode: "1",
                amount: 0,
            },
            {
                carcCode: "2",
                amount: 0,
            },
            {
                carcCode: "4",
                amount: 0,
            }
        ],
        uncoveredCode: null,
        serviceExpanded: false,
    });
    return [...descriptioned, squashed];
}
